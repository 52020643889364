import Alpine from 'alpinejs'

export const initIntersectObserver = () => {
    Alpine.data('intersectObserver', () => ({
        entered: false,
        enter() {
            this.entered = true
        },
    }))
}
