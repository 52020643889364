import Alpine from 'alpinejs'

export const initMenuToggle = () => {
    Alpine.data('menuToggle', () => ({
        hidden: true,
        init() {},
        handleClick() {
            this.hidden = !this.hidden
        },
    }))
}
